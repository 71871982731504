<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="notificationData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="6">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Notification
            </h6>
          </b-col>

          <b-col cols="6">
            <b-button
              v-if="$router.name == 'notification-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'notification-view',
                  params: { id: notificationData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'notification-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'notification-view',
                  params: { id: notificationData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'notification-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des notifications</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="">
          <!-- Agent -->
          <b-col cols="6" md="3">
            <!-- sku_agent -->
            <validation-provider
              #default="validationContext"
              name="sku_agent"
              rules="required"
            >
              <b-form-group
                label="Agent"
                label-for="sku_agent"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="notificationData.sku_agent"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="agentOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="sku_agent"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  Sélectionner l'Agent svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- type -->
          <b-col cols="6" md="3">
            <validation-provider
              #default="validationContext"
              name="type"
              rules="required"
            >
              <b-form-group
                label="Type de notification"
                label-for="type"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="type"
                  v-model="notificationData.type"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Demande d'explication"
                  class=""
                />
                <b-form-invalid-feedback>
                  Sélectionner Type svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- ref -->
          <b-col cols="6" md="3">
            <validation-provider
              #default="validationContext"
              name="ref"
              rules="required"
            >
              <b-form-group
                label="Référence / Code"
                label-for="ref"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="ref"
                  v-model="notificationData.ref"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: DFJ/AVR23/0001245..."
                  class=""
                />
                <b-form-invalid-feedback>
                  Compléter Référence svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- destination_adress -->
          <b-col cols="6" md="3">
            <validation-provider
              #default="validationContext"
              name="destination_adress"
              rules="required"
            >
              <b-form-group
                label="Adresse de destination"
                label-for="destination_adress"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="destination_adress"
                  v-model="notificationData.destination_adress"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: à Goma;..."
                  class=""
                />
                <b-form-invalid-feedback>
                  Compléter Référence svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- date -->
          <b-col cols="6" md="3">
            <validation-provider
              #default="validationContext"
              name="date"
              rules="required"
            >
              <b-form-group label="Date" label-for="date">
                <b-form-datepicker
                  id="date"
                  v-model="notificationData.date"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 30 Juin 1960"
                  class="mb-1"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Completer Date
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- object -->
          <b-col cols="12" md="12">
            <validation-provider
              #default="validationContext"
              name="object"
              rules="required"
            >
              <b-form-group
                label="Objet"
                label-for="object"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="object"
                  v-model="notificationData.object"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Notification Fin Contrat;..."
                  class=""
                />
                <b-form-invalid-feedback>
                  Compléter objet svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- description -->
          <b-col cols="12" md="12">
            <validation-provider
              #default="validationContext"
              name="description"
              rules="required"
            >
              <b-form-group
                label="Description"
                label-for="description"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="description"
                  v-model="notificationData.description"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Vous pouvez toujours passer au bureau pour plus des details"
                  class=""
                />
                <b-form-invalid-feedback>
                  Compléter description svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- content -->
          <b-col cols="12" md="12">
            <validation-provider
              #default="validationContext"
              name="content"
              rules="required"
            >
              <b-form-group
                label="Contenu / Corps /  Details"
                label-for="content"
                :state="getValidationState(validationContext)"
              > 
                <quill-editor
                  :options="snowOption"
                  id="content"
                  v-model="notificationData.content"
                  :state="getValidationState(validationContext)"
                  class=""
                />

                <b-form-invalid-feedback>
                  Compléter contenu enrichi svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- notes -->
          <b-col cols="12" md="12">
            <b-form-group label="Notes (Bas de page)" label-for="notes">
              <b-form-input
                id="notes"
                v-model="notificationData.notes"
                trim
                placeholder="Ex: Vous pouvez toujours passer au bureau pour plus des details"
                class=""
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="notificationData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BMedia,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BAvatar,
  BFormFile,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
  BMediaBody,
  BImg,
} from "bootstrap-vue";

// alerts

import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "notification";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormDatepicker,
    Logo,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BImg,
    BAvatar,
    BTable,
    BCardHeader,
    BCardTitle,

    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      agentOptions: [], 
      snowOption: {
        theme: 'snow',
      },
    };
  },
  props: {
    notificationData: {
      type: Object,
    },
    defaultnotificationData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    if (router.currentRoute.name == moduleName + "-add-new") {
      var now = new Date();
      this.sku_notification = "ag-" + now.getTime();

      this.operationTitle = "Nouvelle ";
      this.imageActionText = "Ajouter ";
      this.operationIcon = "AddIcon";

      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.sku_notification = router.currentRoute.params.id;
      this.operationTitle = "Détails de '";
      this.imageActionText = "?";
      this.operationIcon = "EyeIcon";

      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }

    if (router.currentRoute.name == moduleName + "-edit") {
      this.sku_notification = router.currentRoute.params.id;
      this.operationTitle = "Modification du '";
      this.operationIcon = "EditIcon";
      this.imageActionText = "Modifier";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier";
      this.actionIndex = 3;
      this.showed = true;
    }

    // get agents
    await myApi
      .get(`agent`)
      .then((response) => {
        // default action
        var data = response.data.data;
        console.log(data);

        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.agentOptions.push({
            label:
              data[i].firstname +
              " " +
              data[i].lastname +
              " " +
              data[i].surname,
            value: data[i].sku_agent,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    calculateRemainingNotificationSumDays() {
      // ------------------------------
      // ------------------------------
    },
    reinitForm() {
      this.$props.notificationData = this.defaultnotificationData;

      if (router.currentRoute.name == "notification-add-new") {
        // init empty notification data
        // notificationData = this.defaultnotificationData;
      } else {
        // requirement //
        // store
        //   .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
        //     id: router.currentRoute.params.id,
        //   })
        //   .then((response) => {
        //     notificationData.value = response.data;
        //   })
        //   .catch((error) => {
        //     if (error.response.status === 404) {
        //       notificationData.value = undefined;
        //     }
        //   });
      }
      // remove all occurences
      // initialise 1 element
      // this.$props.notificationData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    async addUpdateItemAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "notification-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "notification-view") {
        method = "get";
        params = "/" + this.notificationData.id;
      }
      if (router.currentRoute.name == "notification-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.notificationData.id;
      }

      // finalise form data
      formData = this.$props.notificationData;

      await myApi({
        url: "notification" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "notification-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "notification-view" });
    },
    goToNextItem() {
      router.push({ name: "notification-list" });
    },
    goToList() {
      router.push({ name: "notification-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-notification";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetnotificationData = () => {
      // this.notificationData = this.$props.notificationData;
    };

    let { refFormObserver, getValidationState, resetForm } = formValidation(
      resetnotificationData
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>